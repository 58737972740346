import { useSetAtom } from 'jotai';

import cloudFrontLocationAtom from './cloudFrontLocationAtom';

const useSetCloudFrontLocation = () => {
  const setCloudFrontLocation = useSetAtom(cloudFrontLocationAtom);

  return setCloudFrontLocation;
};

export default useSetCloudFrontLocation;
