import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import loadable from '@loadable/component';
import Link from 'components/consumer/Link';
import avo from 'analytics/avo';

import IconMapMarker2 from 'components/icons/IconMapMarker2';
import useUserData from 'data-hooks/useUserData';
import useUserCurrentPageType from 'hooks/useUserCurrentPageType';
import useContentfulPromotionContent from 'hooks/useContentfulPromotionContent';
import useClosestStoreZipcode from 'hooks/useClosestStoreZipcode';
import useToggleIsLoginSidebarOpen from 'global-state/isLoginSidebarOpen/useToggleIsLoginSidebarOpen';
import DesktopTopNavUI from './DesktopTopNavUI';
import useCarouselKey from './useCarouselKey';

const Carousel = loadable(() => import('nuka-carousel'));

const DesktopTopNav = ({ isShopByOpen, setIsShopByLinkHovered }) => {
  const carouselKey = useCarouselKey();
  const [bannerItems, setBannerItems] = useState([]);

  const {
    loading: promotionDataLoading,
    promotionData,
  } = useContentfulPromotionContent();

  const { isLoggedIn, firstName } = useUserData();

  const toggleIsLoginSidebarOpen = useToggleIsLoginSidebarOpen();

  const { storeLocation = null, isStoreZipLoading } = useClosestStoreZipcode();
  const storeLinkText = `Wanna take Joybird pieces for a test drive? Stop by our `;
  const virtualStoreText = `Want to see Joybird pieces up close? `;
  const currentPage = useUserCurrentPageType();

  useEffect(() => {
    if (isStoreZipLoading || promotionDataLoading) {
      return;
    }
    const updatedBannerItems = [];
    if (promotionData?.secondaryBannerText) {
      updatedBannerItems.push(
        <div
          key="banner-item-contentful"
          // eslint-disable-next-line react/no-danger
          dangerouslySetInnerHTML={{
            __html: promotionData.secondaryBannerText,
          }}
          className="flex items-center justify-center gap-x-0.5"
        />
      );
    }
    if (storeLocation?.name) {
      updatedBannerItems.push(
        <div
          key={`banner-item-${storeLocation.zip}`}
          className="DesktopTopNavUI__store-name flex items-center justify-center gap-x-0.5"
        >
          <IconMapMarker2 height={12} />
          {storeLocation.name === 'Virtual Showroom'
            ? virtualStoreText
            : storeLinkText}
          {storeLocation.name === 'Virtual Showroom' ? (
            <button
              onClick={() => {
                avo.ctaClicked({
                  text: storeLinkText,
                  targetUrl: storeLocation.url,
                  cta: avo.Cta.RETAIL_STORE,
                  component: avo.Component.NAVBAR,
                  location: currentPage,
                });
              }}
              type="button"
            >
              Chat with a Design Consultant now
            </button>
          ) : (
            <Link
              to={storeLocation.url}
              onClick={() => {
                avo.ctaClicked({
                  text: storeLinkText,
                  targetUrl: storeLocation.url,
                  cta: avo.Cta.RETAIL_STORE,
                  component: avo.Component.NAVBAR,
                  location: currentPage,
                });
              }}
            >
              {storeLocation.name}
            </Link>
          )}
          !
        </div>
      );
    }
    setBannerItems(updatedBannerItems);
  }, [
    isStoreZipLoading,
    storeLocation,
    promotionDataLoading,
    promotionData,
    currentPage,
    storeLinkText,
    virtualStoreText,
  ]);

  return (
    <DesktopTopNavUI
      isShopByOpen={isShopByOpen}
      setIsShopByLinkHovered={setIsShopByLinkHovered}
      leftItems={[
        {
          label: 'Customer Photos',
          id: 'customer-photos',
          url: '/customer-photos/',
        },
        {
          label: 'Showrooms',
          id: 'showrooms',
          url: '/showrooms',
        },
        {
          label: 'Shop by',
          id: 'shop-by',
          icon: 'dropdown-arrow',
        },
      ]}
      rightItems={[
        isLoggedIn
          ? {
              label: `Hi, ${firstName}`,
              url: '/customer/dashboard/',
              id: 'account-logged-in',
              icon: 'IconJoybirdSymbol',
            }
          : {
              label: 'Account',
              url: '',
              id: 'account',
              icon: 'IconJoybirdSymbol',
              onClick: event => {
                event.preventDefault();
                toggleIsLoginSidebarOpen();
              },
            },
        {
          label: 'Blog',
          id: 'blog',
          url: '/blog/',
        },
        {
          label: 'Help',
          id: 'help',
          url: 'https://help.joybird.com/',
        },
      ]}
      importantMessage={
        <Carousel
          slidesToShow={1}
          swiping={false}
          transitionMode="fade"
          autoplayInterval={7000}
          autoplay
          disableEdgeSwiping
          withoutControls
          wrapAround
          key={carouselKey}
        >
          {bannerItems}
        </Carousel>
      }
    />
  );
};

DesktopTopNav.propTypes = {
  isShopByOpen: PropTypes.bool,
  setIsShopByLinkHovered: PropTypes.func,
};

export default DesktopTopNav;
