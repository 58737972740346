import { useEffect, useMemo } from 'react';

import useSetCloudFrontLocation from 'global-state/cloudFrontLocation/useSetCloudFrontLocation';
import useWarehouseIdForZipCode from 'hooks/useWarehouseIdForZipCode';
import { DEFAULT_WAREHOUSE_ID } from 'commons/constants';
import useCloudfrontLocationAPI from './useCloudFrontLocationAPI';

const EMPTY_LOCATION = {};

// This hook is used to get the user's location using the CloudFront API.
// The values are returned in a standard format to allow the
// useRefreshUserLocation hook to return the best available location for the
// user.
const useLocationFromCloudFront = ({ skip = false }) => {
  const {
    error: locationFromCloudFrontError,
    isLoading: isLocationLoading,
    location: locationFromCloudFront,
    // If user zip code can't be used, get location from CloudFront instead
  } = useCloudfrontLocationAPI({ skip });

  const setCloudFrontLocation = useSetCloudFrontLocation();

  useEffect(() => {
    setCloudFrontLocation(locationFromCloudFront);
  }, [locationFromCloudFront, setCloudFrontLocation]);

  const countryNameFromCloudFront = locationFromCloudFront?.country_name;

  // If locationFromCloudfront includes a country name, and it is not the
  // United States, this as an international user so we should assign them to
  // the default warehouse ID (12) and set an empty location object.
  const isInternationalUser =
    !skip &&
    !!countryNameFromCloudFront &&
    countryNameFromCloudFront !== 'United States';

  const enableWarehouseIdForCloudfrontLocation =
    !skip && !!locationFromCloudFront?.postal_code && !isInternationalUser;

  const {
    error: warehouseIdForCloudfrontLocationError,
    loading: isWarehouseIdLoading,
    warehouseId: warehouseIdForCloudFrontLocation,
  } = useWarehouseIdForZipCode(
    enableWarehouseIdForCloudfrontLocation
      ? locationFromCloudFront?.postal_code
      : undefined
  );

  const shouldUseLocation =
    !skip &&
    countryNameFromCloudFront &&
    !locationFromCloudFrontError &&
    !warehouseIdForCloudfrontLocationError;

  const location = useMemo(() => {
    if (!shouldUseLocation) {
      return undefined;
    }

    if (isInternationalUser) {
      return EMPTY_LOCATION;
    }

    const {
      city: cityName,
      country_name: country,
      country_region_name: state,
      country_region: stateShortName,
      postal_code,
    } = locationFromCloudFront;

    return {
      city: cityName || state,
      country,
      state,
      stateShortName,
      zip: `${postal_code}`,
    };
  }, [isInternationalUser, locationFromCloudFront, shouldUseLocation]);

  const warehouseId = useMemo(() => {
    if (!shouldUseLocation) {
      return undefined;
    }

    if (isInternationalUser) {
      return DEFAULT_WAREHOUSE_ID;
    }

    return warehouseIdForCloudFrontLocation;
  }, [
    isInternationalUser,
    shouldUseLocation,
    warehouseIdForCloudFrontLocation,
  ]);

  return {
    isLoading: !skip
      ? !locationFromCloudFrontError &&
        !warehouseIdForCloudfrontLocationError &&
        (isLocationLoading || isWarehouseIdLoading)
      : false,
    location,
    shouldUseLocation,
    warehouseId,
  };
};

export default useLocationFromCloudFront;
