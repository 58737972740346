import React from 'react';
import loadable from '@loadable/component';

import usePostHogFeatureFlag from 'hooks/usePostHogFeatureFlag';
import isNewConsumerApp from 'commons/isNewConsumerApp';

const ConsumerInstantSearchLegacy = loadable(() =>
  import(/* webpackPrefetch: true */ './instant-search-desktop')
);

const ConsumerInstantSearchStaticProducts = loadable(() =>
  import(/* webpackPrefetch: true */ './instant-search-desktop-static')
);

const ConsumerInstantSearch = props => {
  const postHogFlag = usePostHogFeatureFlag('instantSearchStatic');

  if (isNewConsumerApp || postHogFlag === 'test') {
    return <ConsumerInstantSearchStaticProducts {...props} />;
  }
  return <ConsumerInstantSearchLegacy {...props} />;
};

export default ConsumerInstantSearch;
