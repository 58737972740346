const desktopStaticNavItems = [
  {
    id: 'living-room',
    label: 'Living Room',
    longLabel: 'Living Room Furniture',
    url: '/living-room-furniture/',
    image: 'https://joybird2.imgix.net/user-uploads-staging/living1.jpg',
    items: [
      {
        label: 'Sofas + Sectionals',
        id: 'sofas-and-sectionals',
        url: '/sofas-and-sectionals/',
        mobileImage:
          'https://joybird2.imgix.net/configurations/pid_9514/-CF200-WS03/9514-CF200-WS03-lewis-sofa-taylor-felt-grey-t1-1_t.png',
        categoryPageLabel: 'Sofas, Sectionals & Loveseats',
        items: [
          {
            image:
              'https://joybird2.imgix.net/configurations/pid_9514/-CF200-WS03/9514-CF200-WS03-lewis-sofa-taylor-felt-grey-t1-1_t.png',
            label: 'Sofas + Loveseats',
            id: 'sofas-and-loveseats',
            url: '/sofas/',
          },
          {
            image:
              'https://joybird2.imgix.net/configurations/pid_9511/-CF494-WS03/9511-CF494-WS03-lewis-sectional-taylor-felt-gray-t1-1_t.png',
            label: 'Sectionals',
            id: 'sectionals',
            url: '/sectionals/',
          },
          {
            image:
              'https://joybird2.imgix.net/configurations/pid_8696/-CF033-WS03/8696-CF033-WS03-hughes-sleeper-sofa-bentley-daisey-t1-1_t.png',
            label: 'Sleeper Sofas',
            id: 'sleeper-sofas',
            url: '/sleeper-sofas/',
          },
          {
            image:
              'https://joybird2.imgix.net/configurations/pid_8153/-CF020/8153-CF020-bryant-ottoman-key-largo-coral-t1-2_t.png',
            label: 'Modular Sofas',
            id: 'modular-sofas',
            url: '/modular-sofas/',
          },
          {
            image:
              'https://joybird2.imgix.net/configurations/pid_9289/-CF333-WS03/9289-CF333-WS03-chelsea-apartment-sofa-essence-aqua-t1-1_t.png',
            label: 'Apartment Sofas',
            id: 'apartment-sofas',
            url: '/apartment-sofas/',
          },
          {
            image:
              'https://joybird2.imgix.net/product_hero/9389/-CF410-WS03/9389-CF410-WS03-briar-sectional-with-storage-royale-cobalt-t1-7_ts.png',
            label: 'Storage Sectionals',
            id: 'storage-sectionals',
            url: '/sectionals/?features=storage ',
          },
          {
            image:
              'https://joybird2.imgix.net/configurations/pid_3374/-CF333-WS03/3374-CF333-WS03-anton-daybed-essence-aqua-t1-2_t.png',
            label: 'Chaises + Daybeds',
            id: 'chaises-and-daybeds',
            url: '/daybeds-chaises/',
          },
          {
            label: 'Shop All Sofas + Sectionals',
            id: 'shop-all-sofas-and-sectionals',
            url: '/sofas-and-sectionals/',
            hideOnMobile: true,
          },
        ],
      },
      {
        label: 'Chairs',
        id: 'chairs',
        url: '/chairs/',
        mobileImage:
          'https://joybird2.imgix.net/configurations/pid_6653/-CF033-W03/6653-CF033-W03-soto-concave-arm-chair-bentley-daisey-t1-2_t.png',
        items: [
          {
            image:
              'https://joybird2.imgix.net/configurations/pid_6653/-CF033-W03/6653-CF033-W03-soto-concave-arm-chair-bentley-daisey-t1-2_t.png',
            label: 'Apartment Chairs',
            id: 'apartment-chairs',
            url: '/apartment-chairs/',
          },
          {
            image:
              'https://joybird2.imgix.net/configurations/pid_657/-CF020-W03/657-CF020-W03-soto-chair-key-largo-coral-t1-2_t.png',
            label: 'Accent Chairs',
            id: 'accent-chairs',
            url: '/accent-chairs/',
          },
          {
            image:
              'https://joybird2.imgix.net/configurations/pid_3289/-CF333-WS03/3289-CF333-WS03-hughes-swivel-chair-essence-aqua-t1-2_t.png',
            label: 'Swivel Chairs',
            id: 'swivel-chairs',
            url: '/swivel-chairs/',
          },
          {
            image:
              'https://joybird2.imgix.net/configurations/pid_3988/-CF033-W03/3988-CF033-W03-paley-rocking-chair-bentley-daisey-t1-2_t.png',
            label: 'Rocking Chairs',
            id: 'rocking-chairs',
            url: '/rocking-chairs/',
          },
          {
            image:
              'https://joybird2.imgix.net/configurations/pid_9127/-CF020-W03/9127-CF020-W03-langham-recliner-key-largo-coral-t1-2_t.png',
            label: 'Recliner Chairs',
            id: 'recliner-chairs',
            url: '/recliners-gliders/',
          },
          {
            image:
              'https://joybird2.imgix.net/configurations/pid_9302/-CL038/9302-CL038-lyle-leather-ottoman-academy-cuero-t1-2_t.png',
            label: 'Ottomans & Benches',
            id: 'ottomans-and-benches',
            url: '/ottomans/',
          },
          {
            label: 'Shop All Chairs',
            id: 'shop-all-chairs',
            url: '/chairs/',
            hideOnMobile: true,
          },
        ],
      },
      {
        label: 'Tables',
        id: 'tables',
        url: '/all-tables/',
        categoryPageLabel: 'Tables and Desks',
        mobileImage:
          'https://joybird2.imgix.net/product_hero/9360/9360-hudson-coffee-table-t1-1_ts.png',
        items: [
          {
            image:
              'https://joybird2.imgix.net/product_hero/9360/9360-hudson-coffee-table-t1-1_ts.png',
            label: 'Coffee & End Tables',
            id: 'coffee-and-end-tables',
            url: '/coffee-and-end-tables/',
          },
          {
            image:
              'https://joybird2.imgix.net/product_hero/10835/hero-juliana-console-table-2.jpg',
            label: 'Console & Entry Tables',
            id: 'console-and-entry-tables',
            url: '/console-and-entry-tables/',
          },
          {
            label: 'Shop All Tables',
            id: 'shop-all-tables',
            url: '/all-tables/',
            hideOnMobile: true,
          },
        ],
      },
      {
        label: 'Storage',
        id: 'storage',
        url: '/all-storage/',
        categoryPageLabel: 'Cabinets and Storage',
        mobileImage:
          'https://joybird2.imgix.net/product_hero/895/-W03/895-W03-simic-media-console-t1-1_ts.png',
        items: [
          {
            image:
              'https://joybird2.imgix.net/product_hero/895/-W03/895-W03-simic-media-console-t1-1_ts.png',
            label: 'Storage & Media Consoles',
            id: 'storage-and-media-consoles',
            url: '/media-consoles/',
          },
          {
            image:
              'https://joybird2.imgix.net/product_hero/9807/9807-lois-bookcase-with-storage-t1-1_ts.png',
            label: 'Bookcases & Shelving',
            id: 'bookcases-and-shelving',
            url: '/bookcases-wall-units/',
          },
          {
            image:
              'https://joybird2.imgix.net/product_hero/9389/-CF410-WS03/9389-CF410-WS03-briar-sectional-with-storage-royale-cobalt-t1-7_ts.png',
            label: 'Storage Sectionals',
            id: 'storage-sectionals',
            url: '/sofas-and-sectionals/?category=sectionals&features=storage',
          },
          {
            image:
              'https://joybird2.imgix.net/product_hero/9387/-CF388-WS03/9387-CF388-WS03-hughes-sofa-with-storage-royale-gunmetal-t1-2_ts.png',
            label: 'Storage Sofas',
            id: 'storage-sofas',
            url: '/sofas-and-sectionals/?category=sofas&features=storage',
          },
          {
            image:
              'https://joybird2.imgix.net/configurations/pid_9528/-CF477-WS03/9528-CF477-WS03-eliot-storage-ottoman-bloke-goldenrod-t1-3_t.png',
            label: 'Storage Ottomans',
            id: 'storage-ottomans',
            url: '/ottomans/?category=ottomans&features=storage',
          },
          {
            label: 'Shop All Storage',
            id: 'shop-all-storage',
            url: '/all-storage/',
            hideOnMobile: true,
          },
        ],
      },
      {
        label: 'New Arrivals',
        id: 'new-arrivals',
        url: '/living-room-furniture/?sortBy=newest',
        image: 'https://joybird2.imgix.net/user-uploads-staging/living4.jpg',
        isNew: true,
      },
    ],
    secondaryItems: [
      {
        label: 'Customer Photos',
        id: 'customer-photos',
        url: '/customer-photos/?room=Living%20Room',
        image:
          'https://joybird2.imgix.net/user-uploads-staging/webimage-96882348-7B47-4FDB-9EE744D8C92D6782.jpg',
        description: 'Photos from real Joybird customers',
      },
    ],
    ctaLabel: 'Shop All Living Room',
  },
  {
    id: 'dining-room',
    label: 'Dining Room',
    longLabel: 'Dining Room Furniture',
    url: '/dining-room-furniture/',
    image: 'https://joybird2.imgix.net/user-uploads-staging/dining.jpg',
    items: [
      {
        label: 'Dining Tables',
        id: 'dining-tables',
        url: '/dining-tables/',
        image:
          'https://joybird2.imgix.net/product_hero/1925/Toscano-Dining-Set-Taylor-Felt-Grey-T3-209.jpg',
        mobileImage:
          'https://joybird2.imgix.net/user-uploads-staging/dining-tables-1592512539052.png',
      },
      {
        label: 'Dining Chairs',
        id: 'dining-chairs',
        url: '/dining-chairs/',
        image:
          'https://joybird2.imgix.net/product_hero/10205/Calista-dining-table-T4626.jpg',
        mobileImage:
          'https://joybird2.imgix.net/user-uploads-staging/dining-chairs-1592512527100.png',
      },
      {
        label: 'Bar Carts + Cabinets',
        id: 'bar-carts-cabinets',
        url: '/bar-carts-cabinets/',
        image:
          'https://joybird2.imgix.net/product_hero/10605/Henri-sofa_2_Bar_final.jpg',
        mobileImage:
          'https://joybird2.imgix.net/product_hero/9791/9791-piper-cabinet-t1-1_ts.png',
      },
      {
        label: 'New Arrivals',
        id: 'new-arrivals',
        url: '/dining-room-furniture/?sortBy=newest',
        image: 'https://joybird2.imgix.net/user-uploads-staging/dining2.jpg',
        isNew: true,
      },
    ],
    secondaryItems: [
      {
        label: 'Customer Photos',
        id: 'customer-photos',
        url: '/customer-photos/?room=Dining%20Room',
        image:
          'https://joybird2.imgix.net/user-uploads-staging/webimage-2D15B9E6-8462-4E39-9681C754F72C97CD.jpg?rect=60,0,740,423',
        description: 'Photos from real Joybird customers',
      },
    ],
    ctaLabel: 'Shop All Dining Room',
  },
  {
    id: 'bedroom',
    label: 'Bedroom',
    longLabel: 'Bedroom Furniture',
    url: '/bedroom/',
    image: 'https://joybird2.imgix.net/user-uploads-staging/bedroom3.jpg',
    items: [
      {
        label: 'Beds',
        id: 'beds',
        url: '/beds/',
        image:
          'https://joybird2.imgix.net/product_hero/4188/Hughes-Bed-Cordova-Mineral-T2-056.jpg',
        mobileImage:
          'https://joybird2.imgix.net/product_hero/4188/-CF200-WS03/4188-CF200-WS03-hughes-bed-taylor-felt-grey-t1-2_ts.png',
      },
      {
        label: 'Nightstands',
        id: 'nightstands',
        url: '/nightstands/',
        image:
          'https://joybird2.imgix.net/product_hero/10826/JB_Eliza_4B_Var2_JB_Wallpaper_V1_01_MQO_0003.jpg',
        mobileImage:
          'https://joybird2.imgix.net/product_hero/9380/9380-fenton-nightstand-t1-2_ts.png',
      },
      {
        label: 'Dressers + Chests',
        id: 'dressers-and-chests',
        url: '/dressers-chests/',
        image:
          'https://joybird2.imgix.net/product_hero/10032/Ackley-Dresser-T2-239.jpg',
        mobileImage:
          'https://joybird2.imgix.net/product_hero/9381/9381-fenton-dresser-t1-1_ts.png',
      },
      {
        label: 'New Arrivals',
        id: 'new-arrivals',
        url: '/bedroom/?sortBy=newest',
        isNew: true,
        image: 'https://joybird2.imgix.net/user-uploads-staging/bedroom.jpg',
      },
    ],
    secondaryItems: [
      {
        label: 'Customer Photos',
        id: 'customer-photos',
        url: '/customer-photos/?room=Bedroom',
        image:
          'https://joybird2.imgix.net/user-uploads-staging/webimage-32E7F1C6-B2C2-41F2-B9F51D66DD0AC9AA.jpg',
        description: 'Photos from real Joybird customers',
      },
    ],
    ctaLabel: 'Shop All Bedroom',
  },
  {
    id: 'home-office',
    label: 'Home Office',
    longLabel: 'Home Office Furniture',
    url: '/home-office-furniture/',
    image:
      'https://joybird2.imgix.net/user-uploads-staging/Auden-apartment-desk_11192021_24368_full.jpg',
    items: [
      {
        label: 'Desks',
        id: 'desks',
        url: '/desks/',
        image:
          'https://joybird2.imgix.net/product_hero/6652/RAW_Xavier-Apt-Desk-T4-0295.jpg',
        mobileImage:
          'https://joybird2.imgix.net/user-uploads-staging/office-desks@2x-1587760453135.png',
      },
      {
        label: 'Bookcases',
        id: 'bookcases',
        url: '/bookcases-wall-units/',
        image:
          'https://joybird2.imgix.net/product_hero/9807/Calm-Days-Lois-Bookcase-With-Storage-T3-849-PDP.jpg',
        mobileImage:
          'https://joybird2.imgix.net/user-uploads-staging/office-bookcases@2x-1587760409705.png',
      },
      {
        label: 'Office Chairs',
        id: 'office-chairs',
        url: '/office-chairs/',
        image:
          'https://joybird2.imgix.net/product_hero/658/Soto-Leather-Chair-Academy-Cuero-T2-073.jpg',
        mobileImage:
          'https://joybird2.imgix.net/product_hero/10882/-hrbrntrl/10882-hrbrntrl-corbyn-office-chair-t1-1_ts.png',
      },
      {
        label: 'New Arrivals',
        id: 'new-arrivals',
        url: '/home-office-furniture/?sortBy=newest',
        isNew: true,
        image: 'https://joybird2.imgix.net/user-uploads-staging/office.jpg',
      },
    ],
    secondaryItems: [
      {
        label: 'Customer Photos',
        id: 'customer-photos',
        url: '/customer-photos/?room=Home%20Office',
        image:
          'https://joybird2.imgix.net/user-uploads-staging/webimage-39654323-C122-4263-B446A31A4CFDB8F6.jpg',
        description: 'Photos from real Joybird customers',
      },
    ],
    ctaLabel: 'Shop All Home Office',
  },
  {
    id: 'outdoor',
    label: 'Outdoor',
    longLabel: 'Outdoor Furniture',
    url: '/outdoor-furniture/',
    image: 'https://joybird2.imgix.net/user-uploads-staging/outdoor.jpg',
    items: [
      {
        label: 'Outdoor Sofas',
        id: 'outdoor-sofas',
        url: '/outdoor-sofas/',
        image:
          'https://joybird2.imgix.net/product_hero/10312/Buy-More-Save-More-Catalina-Outdoor-Sofa-T4-0071.jpg',
        mobileImage:
          'https://joybird2.imgix.net/user-uploads-staging/outdoor-sofas-1588272484868.png',
      },
      {
        label: 'Outdoor Chairs',
        id: 'outdoor-chairs',
        url: '/outdoor-chairs/',
        image:
          'https://joybird2.imgix.net/product_hero/9736/LBC-Outdoor-Scout-Outdoor-Collection-T4-656-pdp.jpg',
        mobileImage:
          'https://joybird2.imgix.net/user-uploads-staging/outdoor-chairs-1588272510614.png',
      },
      {
        label: 'Outdoor Tables',
        id: 'outdoor-tables',
        url: '/outdoor-tables/',
        image:
          'https://joybird2.imgix.net/product_hero/10952/Monterey-Outdoor-Oval-Dining-Table161.jpg',
        mobileImage:
          'https://joybird2.imgix.net/user-uploads-staging/outdoor-tables-1588272548375.png',
      },
      {
        label: 'New Arrivals',
        id: 'new-arrivals',
        image: 'https://joybird2.imgix.net/user-uploads-staging/outdoor2.jpg',
        url: '/outdoor-furniture/?sortBy=newest',
        isNew: true,
      },
    ],
    secondaryItems: [
      {
        label: 'Outdoor Lookbook',
        id: 'outdoor-lookbook',
        image:
          'https://joybird2.imgix.net/user-uploads-staging/webimage-15879640-1EC8-431B-BA61E52E1D3D2FA3.jpg',
        description: 'Shop our outdoor lookbook',
        descriptionTextColor: 'light',
        url: '/lookbooks/outdoor-2024',
      },
      {
        label: 'Customer Photos',
        id: 'customer-photos',
        image:
          'https://inspire3.imgix.net/rackspace/customer_photos/9722/c525f880-bbca-11eb-a0ce-2daec7caf91a.jpeg',
        description: 'Photos from real Joybird customers',
        descriptionTextColor: 'light',
        url: '/customer-photos/?room=Outdoor%20Room',
      },
    ],
    ctaLabel: 'Shop All Outdoor',
  },
  {
    id: 'decor',
    label: 'Decor',
    longLabel: 'Decor + Accessories',
    categoryPageLabel: 'Decoration Accessories',
    url: '/decoration-accessories/',
    image:
      'https://joybird2.imgix.net/user-uploads-staging/Notch-Modular-6pc-Sectional-Merit-Dove-567.jpg',
    items: [
      {
        label: 'Pillows',
        id: 'pillows',
        url: '/pillows/',
        image:
          'https://joybird2.imgix.net/product_hero/8269/Camilla_bed_sunbrella_p_wisteria-1120-0015.jpg',
        mobileImage:
          'https://joybird2.imgix.net/product_hero/5602/-CF200/5602-CF200-decorative-knife-edge-pillows-18-x-18-(set-of-2)-taylor-felt-grey-t1-1_ts.png',
      },
      {
        label: 'Throws',
        id: 'throws',
        url: '/throws/',
        image:
          'https://joybird2.imgix.net/product_hero/11272/Mindi-Throw-01.jpg',
        mobileImage:
          'https://joybird2.imgix.net/product_hero/10732/10732-mira-throw-t1-1_ts.png',
      },
      {
        label: 'Rugs',
        id: 'rugs',
        url: '/rugs/',
        image:
          'https://joybird2.imgix.net/product_hero/9599/Lewis-sofa_1_Final.jpg',
        mobileImage:
          'https://joybird2.imgix.net/product_hero/9224/9224-aina-rug-t1-1_ts.png',
      },
      {
        label: 'Poufs & Cubes',
        id: 'poufs-and-cubes',
        url: '/poufs/',
        image:
          'https://joybird2.imgix.net/product_hero/10408/Buy-More-Save-More-Del-Mar-Ottoman-T4-2-1b.jpg',
        mobileImage:
          'https://joybird2.imgix.net/product_hero/9302/-CL038/9302-CL038-lyle-leather-ottoman-academy-cuero-t1-2_ts.png',
      },
      {
        label: 'Pet Beds',
        id: 'pet-beds',
        url: '/pet-beds/',
        image:
          'https://joybird2.imgix.net/product_hero/11004/Dog-Bed-Royale-Evergreen-11-x.jpg',
        mobileImage: 'https://joybird2.imgix.net/public_img/pet-bed.png',
      },
      {
        label: 'Lighting',
        id: 'lighting',
        url: '/all-lighting/',
        image:
          'https://joybird2.imgix.net/product_hero/3369/Camille-Bed-Royale-Blush-T2-109.jpg',
        mobileImage:
          'https://joybird2.imgix.net/product_hero/11096/Sophi-Table-Lamp-T1-1.png',
      },
      {
        label: 'Wall Decor',
        id: 'wall-decor',
        url: '/wall-decor/',
        image:
          'https://joybird2.imgix.net/product_hero/10881/Collins_Chair_Nico_Oyster_0712.jpg',
        mobileImage:
          'https://joybird2.imgix.net/product_hero/10881/coll-Wall-Art-T1-052.jpg',
      },
      {
        label: 'Wallpaper',
        id: 'wallpaper',
        url: '/wallpaper/',
        image:
          'https://joybird2.imgix.net/product_hero/11125/SF648-ScandiFloral-Teal-Kitchen.jpg',
        mobileImage: 'https://joybird2.imgix.net/public_img/wallpaper.png',
      },
      {
        label: 'Planters',
        id: 'plants-and-planters',
        url: '/plants-and-planters/',
        image:
          'https://joybird2.imgix.net/product_hero/9534/New-Planter-Shots-SM-045.jpg',
        mobileImage:
          'https://joybird2.imgix.net/product_hero/9193/9193-zane-(small)-planter-t1-2_ts.png',
      },
      {
        label: 'Mirrors',
        id: 'mirrors',
        url: '/mirrors/',
        image:
          'https://joybird2.imgix.net/product_hero/10932/Camilla_bed_sunbrella_p_wisteria-1120-0145.jpg',
        mobileImage:
          'https://joybird2.imgix.net/product_hero/10390/10390-frances-mirror-t1-1_ts.png',
      },
      {
        label: 'Tableware',
        id: 'tableware',
        url: '/tableware/',
        image:
          'https://joybird2.imgix.net/product_hero/11226/Dazey-Short-Glasses-(Set-of-4)-Deep-Sage-T1-01.jpg',
        mobileImage:
          'https://joybird2.imgix.net/product_hero/11222/Lilah-Coupe-Glasses-(Set-of-2)-Millicent-T1-01.jpg',
      },
      {
        label: 'New Arrivals',
        id: 'new-arrivals',
        url: '/decoration-accessories/?sortBy=newest',
        image: 'https://joybird2.imgix.net/user-uploads-staging/decor.jpg',
        isNew: true,
      },
    ],
    ctaLabel: 'Shop All Decor',
  },
  {
    id: 'featured',
    label: 'Featured',
    url: '/featured',
    image:
      'https://joybird2.imgix.net/user-uploads-staging/client_joybird_atx-store_3a-vignette1-1-1661962977675.jpeg',
    items: [
      {
        label: 'New Arrivals',
        id: 'new-arrivals',
        url: '/new-arrivals/?sortBy=newest',
        image:
          'https://joybird2.imgix.net/user-uploads-staging/Diane-Modular-Sofa-Royale-Berry-221116-T3-0286.jpg',
      },
      {
        label: 'Ready to Ship',
        id: 'quick-ship',
        url: '/instock/',
        image:
          'https://joybird2.imgix.net/user-uploads-staging/finalv2_logan_brown_stopmotionloop.gif',
      },
      {
        label: 'Fabric of the Month',
        id: 'fabric-of-the-month',
        url: '/fabric-of-the-month/',
        image:
          'https://joybird2.imgix.net/user-uploads-staging/LP-Image-Block-Fabric-of-the-month-category.jpeg',
      },
      {
        label: 'Small Spaces',
        id: 'small-spaces',
        url: '/small-spaces/',
        image:
          'https://joybird2.imgix.net/user-uploads-staging/JB0911-Banners-Homepage-Hero-Desktop-Small-Spaces-3x2-1694468125099.jpeg',
      },
      {
        label: 'Partnerships & Collaborations',
        id: 'collaborations',
        url: '/collaborations/',
        image:
          'https://joybird2.imgix.net/user-uploads-staging/Influencer_DetailsDarling_35-1.jpg',
      },
      {
        label: 'Latest Lookbook',
        id: 'latest-lookbook',
        url: '/lookbooks/summer-2024/',
        image:
          'https://joybird2.imgix.net/user-uploads-staging/JB0713-Banners-Homepage-Category-Thumb-Desktop-JBxSW.jpg',
      },
    ],
  },
  {
    id: 'sale',
    label: 'Sale',
    url: '/shop/',
    color: 'red',
    image:
      'https://jbimages.imgix.net/Category-Thumb2-Sale-396e35b6-986c-48a3-abf2-a92d6884fc94.jpg',
    items: [],
    ctaLabel: 'Shop All Sale',
  },
  {
    id: 'inspiration',
    label: 'Inspiration',
    url: '/customer-photos/',
    image: 'https://joybird2.imgix.net/user-uploads-staging/inspiration.jpeg',
    items: [
      {
        label: 'Customer Photos',
        id: 'customer-photos',
        url: '/customer-photos/',
        description: 'Room inspo, courtesy of real Joybird customers',
        image:
          'https://inspire3.imgix.net/rackspace/customer_photos/9511/1a787a10-2850-11eb-8c0d-a955d1f79aa0.jpeg',
      },
      {
        label: 'Fabric Swatches',
        id: 'fabric-swatches',
        url: '/swatches/',
        description: 'Make your piece your own with our rainbow of fabrics',
        image:
          'https://joybird2.imgix.net/user-uploads-staging/two-squares_colors@2x-1585688761058.jpeg',
      },
      {
        label: 'Collections',
        id: 'collections',
        url: '/collections/',
        description:
          'Discover pieces from furniture families and find your favorites',
        image:
          'https://joybird2.imgix.net/user-uploads-staging/Simic-Media-Console-Walnut-T2-024.jpg',
      },
      {
        label: 'Lookbooks',
        id: 'lookbooks',
        url: '/lookbooks/',
        description: 'Get inspired by our latest shoppable catalog',
        descriptionTextColor: 'light',
        image:
          'https://joybird2.imgix.net/product_hero/11261/GOLDIE-SOFA-ROYALE-HACIENDA-210923-T4-0610.jpg',
      },
      {
        label: 'Blog',
        id: 'blog',
        url: '/blog/',
        description: 'Design inspo, tips & tricks, and inspiring spaces',
        image:
          'https://joybird2.imgix.net/user-uploads-staging/inspirationblog.jpeg',
      },
      // "Joybird Picks" item is commented out until updates to Custom List
      // Configuration type are complete:
      // https://stitchindustries.atlassian.net/browse/CON-3615
      // {
      //   label: 'Joybird Picks',
      //   id: 'joybird-picks',
      //   url: '/joybirds-picks/',
      //   image:
      //     'https://joybird2.imgix.net/product_hero/9167/5-Ways-to-Create-a-Cozy-Bedroom-Shelby-Bed-T3-Uncropped-161-flipped1.jpg',
      //   items: [
      //     {
      //       label: 'Light & Lively',
      //       id: 'light-and-lively',
      //       url: '/joybirds-picks/light-and-lively/',
      //     },
      //     {
      //       label: 'Warm & Inviting',
      //       id: 'warm-and-inviting',
      //       url: '/joybirds-picks/warm-and-inviting/',
      //     },
      //     {
      //       label: 'Seasonal Selections',
      //       id: 'seasonal-selections',
      //       url: '/joybirds-picks/memorial-day-picks/',
      //     },
      //   ],
      // },
    ],
    ctaLabel: 'Shop All Inspiration',
  },
  {
    id: 'free-design-services',
    label: 'Free Design Services',
    url: '/free-design-services/',
    image:
      'https://joybird2.imgix.net/static-v1/design-services-page/Jungalow-set-build-liam-sofa-royale-evergreen-170807-lastudio-0006-layered-square.gif',
    items: [
      {
        label: '3D Space Planner',
        id: 'three-d-space-planner',
        url: 'https://design.joybird.com/',
        image:
          'https://joybird2.imgix.net/user-uploads-staging/webimage-4D34154D-D96F-4D38-806BD38F08A5D610.jpg',
      },
      {
        label: '3D Room Scanner',
        id: 'three-d-room-scanner',
        url: 'https://design.joybird.com/',
        image:
          'https://joybird2.imgix.net/user-uploads-staging/3d-cloud-room-scanner.jpg',
      },
      {
        label: 'Modular Sofa Builder',
        id: 'three-d-sofa-builder',
        url: '/modular-sofas/',
        image:
          'https://joybird2.imgix.net/user-uploads-staging/webimage-3047B78E-2BEA-4566-8BB3430B74E4FCB3.jpg',
      },
    ],
    ctaLabel: 'Joybird Design House',
  },
];

export default desktopStaticNavItems;
