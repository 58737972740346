import { memo, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import avo from 'analytics/avo';
import { isEmpty } from 'lodash';
import useProductPrice from 'data-hooks/useProductPrice';
import useUserCurrentPageType from 'hooks/useUserCurrentPageType';

const QuickViewModalEvents = ({
  productId,
  product,
  slug,
  selectedOptionsVariantSku,
  variantSkuFlashSale,
}) => {
  const { data: priceData } = useProductPrice(productId, slug);
  const [eventTriggered, updateEventTriggered] = useState(false);
  const currentPage = useUserCurrentPageType();
  useEffect(() => {
    if (eventTriggered === false && !isEmpty(product) && !isEmpty(priceData)) {
      avo.quickviewOpened({
        productId: product.id,
        category: product.type.name,
        isClearance: priceData.isClearance,
        name: product.name,
        price: parseFloat(priceData.price.final_price, 10),
        quantity: 1,
        sku: product.sku,
        variant: selectedOptionsVariantSku,
        fullPrice: parseFloat(priceData.price.original_price, 10),
        productFamily: product.family.name,
        contentIds: [product.sku],
        location: currentPage,
        isFlashSale: variantSkuFlashSale,
      });
      updateEventTriggered(true);
    }
  }, [
    priceData,
    product,
    selectedOptionsVariantSku,
    eventTriggered,
    currentPage,
    variantSkuFlashSale,
  ]);
  return null;
};
QuickViewModalEvents.propTypes = {
  productId: PropTypes.string,
  product: PropTypes.objectOf(PropTypes.object),
  slug: PropTypes.string,
  selectedOptionsVariantSku: PropTypes.string,
  variantSkuFlashSale: PropTypes.objectOf(PropTypes.object),
};
export default memo(QuickViewModalEvents);
